<template>
  <b-card no-body title="Patients">
    <div>
      <b-button-toolbar justify class="toolbar-top-margin">
        <b-form-group label-align-sm="left" label-size="md" label-for="filterInput" class="mb-0 search-bar-margin">
          <b-input-group size="sm">
            <b-form-input id="filterInput" v-model="filterBy" type="search" placeholder="Type to Search" debounce="500" />
          </b-input-group>
        </b-form-group>
        <b-button-group class="mr-2 mb-1" horizontal>
          <b-button v-ripple.400="'rgba(255, 255, 255, 0.15)'" variant="outline-primary" class="btn-icon" v-b-modal.add-edit-event v-b-tooltip.hover>
            <feather-icon icon="PlusIcon" />
          </b-button>
          <b-button v-ripple.400="'rgba(255, 255, 255, 0.15)'" variant="outline-primary" class="btn-icon" @click="initalLoadEvents" v-b-tooltip.hover title="Refresh">
            <b-spinner v-if="getLoading" small style="margin-bottom: 3px" />
            <feather-icon icon="RefreshCwIcon" v-if="!getLoading" />
          </b-button>
        </b-button-group>
      </b-button-toolbar>
    </div>
    <b-overlay :show="getLoading" rounded="sm" opacity="0.8">
      <b-table responsive :fields="fields" :items="getEventList" :current-page="currentPage" :sort-by.sync="sortBy" :sort-desc.sync="isSortDesc" sort-icon-left no-local-sorting>
        <template #cell(actions)="data">
          <b-button v-ripple.400="'rgba(255, 255, 255, 0.15)'" variant="outline-primary" class="btn-icon" v-b-modal.add-edit-event @click="editEvent(data)">
            <feather-icon icon="Edit2Icon" size="20" class="text-success mr-1" />
          </b-button>
        </template>
        <template #cell(eventName)="data">
          <b-link to="/pages/compassionEvents/eventRegistration/">{{ data.value }}</b-link>
        </template>
      </b-table>

      <b-card-body class="d-flex justify-content-between flex-wrap pt-0">
        <!-- page length -->
        <div class="col-1">
          <b-form-select id="perPageSelect" :value="getPerPage" size="sm" inline :options="pageOptions" @change="perPageChanged" />
          <p class="text-nowrap mb-md-0 mr-1"><b>Total rows:</b> {{ getTotalRows }}</p>
        </div>

        <!-- pagination -->
        <div>
          <b-pagination
            :value="currentPage"
            :total-rows="getTotalRows"
            :per-page="getPerPage"
            prev-class="prev-item"
            next-class="next-item"
            class="mb-0"
            limit="1"
            hide-ellipsis
            hide-goto-end-buttons
            @change="onPageChanged"
          >
            <template #prev-text>
              <feather-icon icon="ChevronLeftIcon" size="18" />
            </template>
            <template #next-text>
              <feather-icon icon="ChevronRightIcon" size="18" />
            </template>
          </b-pagination>
        </div>
      </b-card-body>
    </b-overlay>
    <b-modal
      ref="modalRef"
      id="add-edit-event"
      centered
      header-bg-variant="info"
      title="Add Compassion Event"
      hide-header-close
      no-close-on-backdrop
      no-close-on-esc
      no-stacking
      scrollable
      style="height: 400px; z-index: 1"
    >
      <b-form-group
        id="event-name"
        label-for="name-input"
        label="Event name"
        description="Enter event name"
        label-cols="3"
        label-align="right"
        :state="eventData.eventName.length > 4"
      >
        <b-form-input id="name-input" :state="eventData.eventName.length > 4" v-model="eventData.eventName" required></b-form-input>
      </b-form-group>
      <b-form-group
        id="event-date"
        label-for="event-date-input"
        label="Date"
        description="Enter event date"
        label-cols="3"
        label-align="right"
        :state="eventData.eventDate.length > 4"
        class="local-size"
      >
        <b-form-input id="event-date-input" :state="eventData.eventDate.length > 4" v-model="eventData.eventDate" required class="local-size" type="date"></b-form-input>
      </b-form-group>
      <b-form-group id="event-notes-grp" label-for="event-notes" label="Notes" label-cols="3" label-align="right" :state="eventData.notes.length > 4">
        <b-form-textarea id="event-notes" v-model="eventData.notes" required :state="eventData.notes.length > 4"></b-form-textarea>
      </b-form-group>

      <template #modal-footer>
        <b-button size="sm" variant="danger" @click="$bvModal.hide('add-edit-event')" :disabled="saving"> Cancel </b-button>
        <b-button size="sm" variant="outline-warning" @click="saveEvent()" :disabled="saving">
          Save
          <b-spinner v-if="saving" small style="margin-bottom: 3px" />
        </b-button>
      </template>
    </b-modal>
  </b-card>
</template>

<script>
import {
  getEventList,
  lookupEvent,
  filterBy,
  sortBy,
  sortOrder,
  setPerPage,
  getLoading,
  onLoadAllEvents,
  getTotalRows,
  getPerPage,
  saveNewEvent
} from "./compassionPartnerRequests";
import { getUserData } from "@/auth/utils";
import { DEFAULT_PAGE_OPTIONS } from "@/listo/defaults";
import { onMounted, ref, reactive, watch } from "@vue/composition-api";
import Ripple from "vue-ripple-directive";
import { useRouter } from "@core/utils/utils";

export default {
  name: "Events",
  components: {},
  directives: {
    Ripple
  },
  setup(_, { root }) {
    const pageOptions = DEFAULT_PAGE_OPTIONS;
    const currentPage = ref(1);
    const isSortDesc = ref(false);
    const saving = ref(false);
    const modalRef = ref(null);
    const eventData = ref({
      eventUid: "",
      eventName: "",
      eventDate: "",
      notes: ""
    });

    const fields = [
      { key: "eventName", label: "Event Name" },
      { key: "eventDate", label: "Date" },
      { key: "notes", label: "Notes" },
      { key: "actions", label: "" }
    ];

    const { router } = useRouter();

    const isUserAdmin = () => getUserData && getUserData.admin;

    const saveEvent = async () => {
      console.log("save partner", eventData);
      saving.value = true;
      //modalRef.value.hide();
      try {
        await saveNewEvent(eventData.value);
      } catch (err) {
        console.error(err);
      } finally {
        saving.value = false;
        modalRef.value.hide();
        await initalLoadEvents();
      }
    };

    const initalLoadEvents = async () => {
      console.debug("loading partners");
      try {
        await onLoadAllEvents({ prevPage: false, nextPage: false, reset: true });
        console.log("found", getEventList);
      } catch (err) {
        root.$bvToast.toast(`Error loading datat:  ${err.message}`, {
          title: "Error",
          autoHideDelay: 4000,
          variant: "danger",
          solid: true,
          appendToast: true
        });
      }
    };

    const editEvent = async (val) => {
      console.debug("editing", val);
      eventData.value.eventUid = val.item.uid;
      eventData.value.eventName = val.item.eventName;
      eventData.value.eventDate = val.item.eventDate;
      eventData.value.notes = val.item.notes;
    };

    const perPageChanged = async (val) => {
      setPerPage(val);
      await initalLoadEvents();
    };

    const onPageChanged = async (val) => {
      if (val == 1) {
        currentPage.value = val;
        await onLoadAllEvents({ prevPage: false, nextPage: false, reset: true });
        return;
      }
      if (currentPage.value > val) {
        await onLoadAllEvents({ prevPage: true, nextPage: false });
        currentPage.value = val;
        return;
      }
      await onLoadAllEvents({ prevPage: false, nextPage: true });
      currentPage.value = val;
    };

    onMounted(async () => {
      filterBy.value = "";
      isSortDesc.value = false;
      await initalLoadEvents();
      try {
        const res = await lookupEvent();
        integrationsList.value = res.data.allIntegrations;
        return integrationsList.value;
      } catch (err) {
        console.error("Error", err);
      }
    });

    watch(filterBy, (newValue, oldValue) => {
      initalLoadEvents();
    });

    watch(sortBy, (newValue, oldValue) => {
      initalLoadEvents();
    });

    watch(isSortDesc, (newValue, oldValue) => {
      sortOrder.value = newValue ? "desc" : "asc";
      initalLoadEvents();
    });

    return {
      getEventList,
      filterBy,
      setPerPage,
      getPerPage,
      getLoading,
      initalLoadEvents,
      getTotalRows,
      fields,
      currentPage,
      pageOptions,
      onPageChanged,
      perPageChanged,
      isUserAdmin,
      sortBy,
      sortOrder,
      isSortDesc,
      eventData,
      saveEvent,
      modalRef,
      saving,
      editEvent
    };
  }
};
</script>

<style scoped>
.toolbar-top-margin {
  margin-top: 10px;
}
.search-bar-margin {
  margin-left: 15px;
}
.new-only-switch {
  margin-left: 20px;
}
.local-size {
  z-index: 1;
}
</style>
